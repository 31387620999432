
import {Component, Vue} from "vue-property-decorator";
import BuroPersonelList from "@/components/lists/BuroPersonelList.vue";
import BuroInfo from "@/components/infos/BuroInfo.vue";
import iletisimList from "@/components/lists/IletisimList.vue";
import BankaHesabiList from "@/components/lists/BankaHesabiList.vue";
import {BuroEntity} from "@/entity/BuroEntity";

@Component({
  components: {BuroInfo, iletisimList, BankaHesabiList, BuroPersonelList}
})

export default class BuroDetayView extends Vue {
  item: BuroEntity = new BuroEntity();

  get action() {
    if (this.item) {
      return "/api/v1/buro/" + this.item.id;
    }
  }

  mounted() {
    let activeProcess = {id: this.$route.params.id, key: 'buro-detay', isRoot: true}
    this.$store.commit('setActiveProcess', activeProcess);
    this.load();
  }

  load() {
    this.$http
        .get('/api/v1/buro/' + this.$route.params.id)
        .then(response => this.item = response.data)
  }
}
