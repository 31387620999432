
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import VekaletList from "@/components/lists/VekaletList.vue";

@Component({
  components: {VekaletList},
})
export default class DeletePersonelDialog extends DeleteDialog {
  @Prop() action!: string;
  @Prop({default: false}) disabled!: boolean;
  @Prop({default: false}) buttonxsmall!: boolean;
  @Prop() item !: any;
  @Prop() tooltip!: string;

  show: boolean = false;
  vekaletleriGoster: boolean = false;
  state: string = "asking";

  get title(): string {
    return "Emin misiniz?";
  }

  load() {
    this.$emit('load');
  }

  get content(): string {
    let vekaletler = this.vekaletler;
    if (vekaletler) {
      return "Silmek istediğiniz personele ait vekalet bilgisi bulunmaktadır. Personeli silmek için" +
          " öncelikle bulunduğu vekaletlerden çıkarmanız gerekmektedir. Vekaletlerden çıkarmak istemiyorsanız personeli " +
          "pasifleştirebilirsiniz.";
    }

    return "Seçtiğiniz kayıt kalıcı olarak silinecektir. Devam etmek istediğinize emin misiniz?";
  }

  get vekaletler() {
    return this.item.vekaletler && this.item.vekaletler.length > 0;
  }


  pasiflestir() {
    this.$http.post('/api/v1/personel/' + this.item.id + '/pasiflestir').then(() => {
      this.$emit('onSuccess')
      this.load();
      this.onClose();
    });
  }

  aktiflestir() {
    this.$http.post('/api/v1/personel/' + this.item.id + '/aktiflestir').then(() => {
      this.$emit('onSuccess')
      this.load();
      this.onClose();
    });
  }
}
